<template>
  <body>
    <component :is="layout">
      <router-view />
    </component>
  </body>
</template>

<script>
export default {
  computed: {
    // Renders a different layout based on the meta property set in router/index.js
    layout() {
      return (this.$route.meta.layout || 'default') + '-layout';
    },
  },

  /**
   * Returns `<head>` information for the site.
   *
   * The values returned here are merely default values and can be infinitely
   * overwritten in lower-level components.
   *
   * @returns {Object}
   */
  head() {
    const { fullPath } = this.$route;
    const url = process.env.VUE_APP_SITE_URL + fullPath;

    const title = 'Reimagine Real Estate Investment';
    const template = '%s | Roofone Property Capital';
    const description =
      'Roofone makes buying real estate properties safer and easier.';

    return {
      title,
      titleTemplate: template,
      meta: [
        { property: 'twitter:card', content: 'summary_large_image' },
        { property: 'og:type', content: 'website' },
        { property: 'og:site_name', content: 'Roofone Property Capital' },
        { property: 'og:url', content: url },
        { vmid: 'og:title', property: 'og:title', content: title },
        {
          vmid: 'og:image',
          property: 'og:image',
          content:
            'https://ik.imagekit.io/roofone/roofone-preview_bWog8phmG8N.jpg',
        },
        { vmid: 'description', name: 'description', content: description },
        {
          vmid: 'og:description',
          name: 'og:description',
          content: description,
        },
      ],
    };
  },
};
</script>

<style src="./assets/css/tailwind.css" />
<style src="./assets/css/app.css" />
