// in each of the filter computation functions,
// "pass" always enters as a truthy value.
// because the idea (in the "filtering") is to skip
// every element that has a property doesn't match the filter the
// user has requested for (if the filter has been set).
// so once that element doesn't fit in that criteria, it's on to the
// next one.
function computePriceFilter(min, max, listingPrice, pass, threshold) {
  if (!min && !max) {
    return true;
  }

  if (min && !max) {
    return listingPrice >= min;
  }

  if (max) {
    pass = listingPrice <= max;

    if (min) {
      pass = listingPrice <= max && listingPrice >= min;
    }
    if (max == threshold && listingPrice >= max) {
      pass = true;
    }
  }

  return pass;
}

function isInteger(val) {
  return val == Number.parseInt(val);
}

// this function checks if the data (from the listing details)
// matches with the filter data from the state.
// and if a threshold is provided, it checks to see if the data
// is lower than the threshold when the filter is equal to the threshold
// and it returns false if so.
function computeFilter(filter, data, pass, threshold) {
  if (filter === null || filter == '') {
    return pass;
  }

  if (filter != data) {
    return false;
  }

  if (isInteger(data) && isInteger(filter)) {
    if (
      (filter == threshold && data < threshold) ||
      (filter < threshold && filter != data)
    ) {
      return false;
    }
  }
  return pass;
}

/**
 * Calculates the monthly mortgage payments for a given total cost, down payment and interest rate.
 * @param {number} propertyInformation - Details about the property.
 * @param {number} propertyInformation.principal - The total cost of the property.
 * @param {number} propertyInformation.downPayment - The amount of money paid upfront.
 * @param {number} propertyInformation.interestRate - The current mortgage interest rate expressed as a decimal.
 * @param {number} propertyInformation.loanTerm - The number of monthly payments.
 * @return {number} The amount of money to be paid monthly.
 */
function calculateMortgageMonthlyPayment({
  principal,
  downPayment,
  interestRate,
  loanTerm,
}) {
  const monthlyPayment =
    (interestRate *
      (principal - downPayment) *
      Math.pow(1 + interestRate, loanTerm)) /
    (Math.pow(1 + interestRate, loanTerm) - 1);
  return Math.round(monthlyPayment * 100) / 100;
}

export {
  isInteger,
  computeFilter,
  computePriceFilter,
  calculateMortgageMonthlyPayment,
};
