import Vue from 'vue';
import AOS from 'aos';
import { sync } from 'vuex-router-sync';
import PortalVue from 'portal-vue';

import Default from '@/layouts/BaseLayout';
import Dark from '@/layouts/DarkLayout';
import App from './App.vue';
import { createRouter } from './router';
import { createStore } from './store';
import Modal from 'vue-js-modal/dist/ssr.nocss';
import Cleave from 'cleave.js';

import 'aos/dist/aos.css';
import 'vue-js-modal/dist/styles.css';

Vue.component('default-layout', Default);
Vue.component('dark-layout', Dark);

Vue.config.productionTip = false;

Vue.use(PortalVue);
Vue.use(Modal);

Vue.directive('cleave', {
  inserted: (el, binding) => {
    el.cleave = new Cleave(el, binding.value || {});
  },
  update: (el) => {
    const event = new Event('input', { bubbles: true });
    setTimeout(function () {
      el.value = el.cleave.properties.result;
      el.dispatchEvent(event);
    }, 100);
  },
});

const createApp = (context) => {
  // create router and store instances
  const router = createRouter();
  const store = createStore();

  // sync so that route state is available as part of the store
  sync(store, router);

  const app = new Vue({
    data: { url: context ? context.url : '' },
    router,
    store,
    mounted() {
      // Animate on scroll library is initialized here
      AOS.init();
    },
    render: (h) => h(App),
  });

  return { app, router, store };
};

export { createApp };
