<template>
  <svg
    id="ffa0e257-4b46-4632-a8d6-93195cbf254d"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="895.68"
    height="517.48"
    viewBox="0 0 895.68 517.48"
  >
    <defs>
      <linearGradient
        id="74471b12-b6be-488b-b2aa-2815e91483d9"
        x1="790.94"
        y1="640.76"
        x2="790.94"
        y2="264.76"
        gradientTransform="translate(1229.99 -336.2) rotate(90)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="gray" stop-opacity="0.25" />
        <stop offset="0.54" stop-color="gray" stop-opacity="0.12" />
        <stop offset="1" stop-color="gray" stop-opacity="0.1" />
      </linearGradient>
      <linearGradient
        id="f5c756da-9879-448e-9194-9478f83d355f"
        x1="785.97"
        y1="446.14"
        x2="785.97"
        y2="321.91"
        gradientTransform="translate(1166.67 -399.94) rotate(90)"
        xlink:href="#74471b12-b6be-488b-b2aa-2815e91483d9"
      />
      <linearGradient
        id="5c9be04e-db0f-4829-87c1-2cfa193e30b8"
        x1="660.92"
        y1="518.19"
        x2="660.92"
        y2="494.17"
        gradientTransform="translate(1146.84 -172.9) rotate(90)"
        xlink:href="#74471b12-b6be-488b-b2aa-2815e91483d9"
      />
      <linearGradient
        id="b92f3a53-b4d2-4abd-916c-aeb632188996"
        x1="433.66"
        y1="605.23"
        x2="433.66"
        y2="235.23"
        gradientTransform="translate(13.58 8.51)"
        xlink:href="#74471b12-b6be-488b-b2aa-2815e91483d9"
      />
      <linearGradient
        id="44577794-7f8e-40ae-89fc-019e5bfac17e"
        x1="428.36"
        y1="413.71"
        x2="428.36"
        y2="291.46"
        gradientTransform="translate(-1.05 11.3)"
        xlink:href="#74471b12-b6be-488b-b2aa-2815e91483d9"
      />
      <linearGradient
        id="666bb7d3-84d0-4835-ad07-c313c2384f21"
        x1="295.22"
        y1="484.61"
        x2="295.22"
        y2="460.98"
        gradientTransform="translate(28.38 37.45)"
        xlink:href="#74471b12-b6be-488b-b2aa-2815e91483d9"
      />
      <linearGradient
        id="59939605-05af-4a9a-9980-f700897f3f8b"
        x1="455.07"
        y1="464.48"
        x2="455.07"
        y2="10.48"
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        xlink:href="#74471b12-b6be-488b-b2aa-2815e91483d9"
      />
      <linearGradient
        id="6c0ba2ee-2d81-48f4-b4cf-f21b525fcc13"
        x1="449.07"
        y1="229.48"
        x2="449.07"
        y2="79.48"
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        xlink:href="#74471b12-b6be-488b-b2aa-2815e91483d9"
      />
      <linearGradient
        id="45b0c09d-2995-447e-8cb2-7f6b59b5e20c"
        x1="298.07"
        y1="316.48"
        x2="298.07"
        y2="287.48"
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        xlink:href="#74471b12-b6be-488b-b2aa-2815e91483d9"
      />
      <linearGradient
        id="e5b0a96a-81f6-4370-afe3-5a7d6a35b55e"
        x1="457.07"
        y1="517.48"
        x2="457.07"
        y2="364.48"
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        xlink:href="#74471b12-b6be-488b-b2aa-2815e91483d9"
      />
      <linearGradient
        id="a1850e5a-cf77-4889-926a-0125112ab273"
        x1="611.23"
        y1="564.74"
        x2="611.23"
        y2="464.74"
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        xlink:href="#74471b12-b6be-488b-b2aa-2815e91483d9"
      />
      <linearGradient
        id="66998849-614e-4d8a-b9ff-f14bbc588ed8"
        x1="611.23"
        y1="660.74"
        x2="611.23"
        y2="602.74"
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        xlink:href="#74471b12-b6be-488b-b2aa-2815e91483d9"
      />
    </defs>
    <title>safe</title>
    <rect
      x="792.23"
      y="49.92"
      width="19.88"
      height="470.41"
      transform="translate(247.6 844.09) rotate(-81.36)"
      fill="#f5f5f5"
    />
    <rect
      x="589.23"
      y="209.59"
      width="376"
      height="490.29"
      transform="translate(58.72 963.57) rotate(-81.36)"
      fill="url(#74471b12-b6be-488b-b2aa-2815e91483d9)"
    />
    <rect
      x="603.48"
      y="229.23"
      width="342.87"
      height="470.41"
      transform="translate(47.16 969.52) rotate(-81.36)"
      fill="#f5f5f5"
    />
    <circle
      cx="580.99"
      cy="251.51"
      r="4.86"
      transform="translate(92.88 596.86) rotate(-81.36)"
      fill="#ff5252"
    />
    <circle
      cx="594.2"
      cy="253.52"
      r="4.86"
      transform="translate(102.12 611.62) rotate(-81.36)"
      fill="#ff0"
    />
    <circle
      cx="607.41"
      cy="255.53"
      r="4.86"
      transform="translate(111.36 626.39) rotate(-81.36)"
      fill="#69f0ae"
    />
    <rect
      x="720.53"
      y="174.84"
      width="124.23"
      height="422.38"
      transform="translate(131.25 910.54) rotate(-81.36)"
      fill="url(#f5c756da-9879-448e-9194-9478f83d355f)"
    />
    <rect
      x="576.36"
      y="328.95"
      width="413.27"
      height="115.12"
      transform="translate(-85.2 -304.51) rotate(8.64)"
      fill="#638eff"
    />
    <rect
      x="628.65"
      y="473.94"
      width="24.02"
      height="28.16"
      transform="translate(-90.24 856.82) rotate(-81.36)"
      fill="url(#5c9be04e-db0f-4829-87c1-2cfa193e30b8)"
    />
    <rect
      x="627.88"
      y="477.73"
      width="26.5"
      height="19.88"
      transform="translate(-71.61 -282.05) rotate(8.64)"
      fill="#638eff"
    />
    <rect
      x="622.77"
      y="511.3"
      width="26.5"
      height="19.88"
      transform="translate(-66.63 -280.9) rotate(8.64)"
      fill="#ff5252"
    />
    <rect
      x="617.67"
      y="544.87"
      width="26.5"
      height="19.88"
      transform="translate(-61.64 -279.75) rotate(8.64)"
      fill="#ff9800"
    />
    <rect
      x="696.41"
      y="506.51"
      width="213.67"
      height="11.59"
      transform="translate(-66.07 -306.13) rotate(8.64)"
      fill="#638eff"
      opacity="0.4"
    />
    <rect
      x="691.31"
      y="540.08"
      width="213.67"
      height="11.59"
      transform="translate(-61.09 -304.98) rotate(8.64)"
      fill="#638eff"
      opacity="0.4"
    />
    <rect
      x="686.21"
      y="573.65"
      width="213.67"
      height="11.59"
      transform="translate(-56.1 -303.83) rotate(8.64)"
      fill="#638eff"
      opacity="0.4"
    />
    <rect
      x="159.15"
      y="254.51"
      width="500.84"
      height="19.56"
      transform="translate(-199.95 -95.55) rotate(-12.6)"
      fill="#f5f5f5"
    />
    <rect
      x="186.23"
      y="243.74"
      width="522"
      height="370"
      transform="translate(-234.92 -83.38) rotate(-12.6)"
      fill="url(#b92f3a53-b4d2-4abd-916c-aeb632188996)"
    />
    <rect
      x="198.08"
      y="269.78"
      width="500.84"
      height="337.4"
      transform="translate(-237.01 -82.86) rotate(-12.6)"
      fill="#f5f5f5"
    />
    <ellipse
      cx="177.11"
      cy="316.25"
      rx="5.17"
      ry="4.78"
      transform="translate(-216.88 -145.01) rotate(-12.6)"
      fill="#ff5252"
    />
    <ellipse
      cx="191"
      cy="313.15"
      rx="5.17"
      ry="4.78"
      transform="translate(-215.87 -142.05) rotate(-12.6)"
      fill="#ff0"
    />
    <ellipse
      cx="204.88"
      cy="310.05"
      rx="5.17"
      ry="4.78"
      transform="translate(-214.86 -139.1) rotate(-12.6)"
      fill="#69f0ae"
    />
    <rect
      x="202.46"
      y="302.76"
      width="449.7"
      height="122.25"
      transform="translate(-221.25 -89.28) rotate(-12.6)"
      fill="url(#44577794-7f8e-40ae-89fc-019e5bfac17e)"
    />
    <rect
      x="207.83"
      y="307.54"
      width="440"
      height="113.28"
      transform="translate(-221.3 -89.16) rotate(-12.6)"
      fill="#638eff"
    />
    <rect
      x="308.61"
      y="498.42"
      width="29.98"
      height="23.63"
      transform="translate(-255.67 -108.38) rotate(-12.6)"
      fill="url(#666bb7d3-84d0-4835-ad07-c313c2384f21)"
    />
    <rect
      x="309.83"
      y="499.96"
      width="28.22"
      height="19.56"
      transform="matrix(0.98, -0.22, 0.22, 0.98, -255.56, -108.32)"
      fill="#638eff"
    />
    <rect
      x="317.12"
      y="532.57"
      width="28.22"
      height="19.56"
      transform="translate(-262.49 -105.94) rotate(-12.6)"
      fill="#ff5252"
    />
    <rect
      x="324.41"
      y="565.18"
      width="28.22"
      height="19.56"
      transform="matrix(0.98, -0.22, 0.22, 0.98, -269.43, -103.57)"
      fill="#ff9800"
    />
    <rect
      x="380.57"
      y="465.95"
      width="227.49"
      height="11.41"
      transform="translate(-243.14 -72.07) rotate(-12.6)"
      fill="#638eff"
      opacity="0.4"
    />
    <rect
      x="387.86"
      y="498.56"
      width="227.49"
      height="11.41"
      transform="translate(-250.08 -69.69) rotate(-12.6)"
      fill="#638eff"
      opacity="0.4"
    />
    <rect
      x="395.15"
      y="531.17"
      width="227.49"
      height="11.41"
      transform="translate(-257.02 -67.32) rotate(-12.6)"
      fill="#638eff"
      opacity="0.4"
    />
    <rect x="170.07" y="18.48" width="568" height="24" fill="#f5f5f5" />
    <rect
      x="159.07"
      y="10.48"
      width="592"
      height="454"
      fill="url(#59939605-05af-4a9a-9980-f700897f3f8b)"
    />
    <rect x="170.07" y="42.48" width="568" height="414" fill="#fff" />
    <circle cx="183.94" cy="30.48" r="5.87" fill="#ff5252" />
    <circle cx="200.07" cy="30.48" r="5.87" fill="#ff0" />
    <circle cx="216.2" cy="30.48" r="5.87" fill="#69f0ae" />
    <rect
      x="194.07"
      y="79.48"
      width="510"
      height="150"
      fill="url(#6c0ba2ee-2d81-48f4-b4cf-f21b525fcc13)"
    />
    <rect x="200.07" y="85.48" width="499" height="139" fill="#638eff" />
    <rect
      x="281.07"
      y="287.48"
      width="34"
      height="29"
      fill="url(#45b0c09d-2995-447e-8cb2-7f6b59b5e20c)"
    />
    <rect x="282.57" y="289.48" width="32" height="24" fill="#638eff" />
    <rect x="282.57" y="330.48" width="32" height="24" fill="#ff5252" />
    <rect x="282.57" y="371.48" width="32" height="24" fill="#ff9800" />
    <rect
      x="367.57"
      y="294.48"
      width="258"
      height="14"
      fill="#638eff"
      opacity="0.4"
    />
    <rect
      x="367.57"
      y="335.48"
      width="258"
      height="14"
      fill="#638eff"
      opacity="0.4"
    />
    <rect
      x="367.57"
      y="376.48"
      width="258"
      height="14"
      fill="#638eff"
      opacity="0.4"
    />
    <rect
      x="364.07"
      y="364.48"
      width="186"
      height="153"
      fill="url(#e5b0a96a-81f6-4370-afe3-5a7d6a35b55e)"
    />
    <path
      d="M559.15,531.41a52.08,52.08,0,0,1,104.17,0v33.33H677.9V531.41a66.67,66.67,0,1,0-133.33,0v33.33h14.58Z"
      transform="translate(-152.16 -191.26)"
      fill="url(#a1850e5a-cf77-4889-926a-0125112ab273)"
    />
    <path
      d="M561.23,530.74a50,50,0,0,1,100,0v32h14v-32a64,64,0,0,0-128,0v32h14Z"
      transform="translate(-152.16 -191.26)"
      fill="#fff"
    />
    <rect x="372.07" y="369.48" width="174" height="142" fill="#638eff" />
    <rect x="372.07" y="369.48" width="174" height="142" fill="#fff" />
    <rect x="372.07" y="397.48" width="174" height="86" fill="#638eff" />
    <path
      d="M624,615.5a12.76,12.76,0,1,0-22,8.74v27.22a9.28,9.28,0,0,0,18.56,0V624.24A12.7,12.7,0,0,0,624,615.5Z"
      transform="translate(-152.16 -191.26)"
      fill="url(#66998849-614e-4d8a-b9ff-f14bbc588ed8)"
    />
    <path
      d="M622.23,617.74a11,11,0,1,0-19,7.53v23.47a8,8,0,1,0,16,0V625.27A11,11,0,0,0,622.23,617.74Z"
      transform="translate(-152.16 -191.26)"
      opacity="0.2"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
