<template>
  <div>
    <portal-target name="image-modal" />
    <div>
      <PageHeader />
      <slot />
      <PageFooter />
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/common/Navbar';
import PageFooter from '@/components/common/Footer';
export default {
  components: { PageHeader, PageFooter },
};
</script>

<style></style>
