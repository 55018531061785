import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import { computeFilter, computePriceFilter } from './utils';

Vue.use(Vuex);
const {
  VUE_APP_API_ROOT : apiRoot,
  VUE_APP_LOCALITIES_URL: locationsUrl
} = process.env;

export const createStore = () =>
  new Vuex.Store({
    strict: process.env.NODE_ENV !== 'production',

    state: () => ({
      searchResults: [],
      locations: null,
      listing: {
        id: 0,
        investment_type: {},
        beds: 0,
        baths: 0,
        address_line_1: '',
        lot_size: 0,
        location: {},
        price: 0,
        cover_image: {},
        images: [],
      },
    }),

    getters: {
      filterResults: state => (
        filters => {
          const { investmentType, price, location, beds, baths } = filters;

          return state.searchResults.filter(listing => {
            let pass = true;
            const isLandListing = listing.investment_type.name === 'Land';
            const listingType = isLandListing ? 'Land' : 'House';

            // investment type filter
            pass = computeFilter(investmentType, listingType, pass);
            if(!pass) {
              return pass;
            }

            // price filter
            pass = computePriceFilter(price.min, price.max, listing.price, pass, 100000000);
            if(!pass) {
              return pass;
            }

            // locations filter
            pass = computeFilter(location, listing.location.locality, pass);
            if(!pass) {
              return pass;
            }

            // beds filter
            pass = computeFilter(beds, listing.beds, pass, 5);
            if(!pass) {
              return pass;
            }

            // baths filter
            pass = computeFilter(baths, listing.baths, pass, 5);
            if(!pass) {
              return pass;
            }

            return pass;
          });
        }
      )
    },

    actions: {
      /**
       * Retrieves list of locations..
       *
       * @returns {void}
       */
      getLocations({ commit }) {
        return axios.get(`${locationsUrl}`)
          .then( response => {
            commit('updateLocations', { locations: Object.values(response.data) });
            return response.data;
          })
          .catch(() => {});
      },

      /**
       * Retrieves listings for the search-results page.
       *
       * @returns {void}
       */
      searchListings({ commit }) {
        return axios
          .get(`${apiRoot}/listings`)
          .then((response) => {
            if (response.status < 200 || response.status >= 300) {
              throw new Error('Could not fetch listings from API server.');
            }

            commit('updateSearchResults', { newResults: response.data });
          })
          .catch(() => null);
      },

      /**
       * Get the details of a single listing.
       */
      getListing({ commit }, id) {
        return axios.get(`${apiRoot}/listings/${id}`).then((response) => {
          if (response.status < 200 || response.status >= 300) {
            throw new Error('Could not fetch listing details from API server.');
          }

          commit('updateListing', response.data);
        });
      },
    },

    mutations: {
      updateSearchResults(state, { newResults }) {
        // without images? someone might be in the process of uploading them
        state.searchResults = newResults.filter(
          (value) => value.cover_image !== null
        );
      },

      updateListing(state, listing) {
        state.listing = { ...state.listing, ...listing };
      },

      updateLocations(state, { locations }) {
        state.locations = locations;
      }
    }
  });
