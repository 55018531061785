<template>
  <div id="contact" class="relative bg-gray-800">
    <div
      class="h-56 bg-gray-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2"
    >
      <img
        class="object-cover object-left-top w-full h-full opacity-50"
        src="https://images.unsplash.com/photo-1499159058454-75067059248a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1651&q=80"
        alt="Contact us"
      />
    </div>
    <div
      class="relative max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:px-8 lg:py-16"
    >
      <div class="md:ml-auto md:w-1/2 md:pl-10">
        <h2
          class="mt-2 text-3xl font-extrabold leading-9 tracking-tight text-white font-heading-sans sm:text-4xl sm:leading-10"
        >
          Get in touch with us
        </h2>
        <p class="mt-3 font-sans text-lg leading-7 text-gray-300">
          For custom property requests, or to learn more about our process, we
          are happy to take your calls Mondays - Saturdays 10:00 AM to 4:00 PM
          GMT+1, and you can send us an email anytime.
        </p>

        <!-- Commented out till we have some functionality for processing contact form submissions -->

        <!-- <div class="mt-8">
          <div class="inline-flex rounded-md shadow">
            <router-link
              to="/contact"
              class="inline-flex items-center justify-center px-5 py-3 text-base font-medium leading-6 text-gray-900 transition duration-150 ease-in-out bg-white border border-transparent rounded-md hover:text-gray-600 focus:outline-none focus:shadow-outline"
            >
              Schedule a call
              <svg
                class="w-5 h-5 ml-3 -mr-1 text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fill-rule="evenodd"
                  d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5zM5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"
                  clip-rule="evenodd"
                />
              </svg>
            </router-link>
          </div>
        </div> -->

        <div class="mt-9">
          <div class="flex">
            <div class="flex-shrink-0">
              <svg
                class="w-6 h-6 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                />
              </svg>
            </div>
            <div class="ml-3 text-base leading-6 text-gray-300">
              <a href="tel:2348075267678" class="hover:text-gray-400">
                +234 807 526 7678
              </a>
              <p class="mt-1">
                Mon-Sat 10am to 4pm GMT+1
              </p>
            </div>
          </div>
          <div class="flex mt-6">
            <div class="flex-shrink-0">
              <svg
                class="w-6 h-6 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                />
              </svg>
            </div>
            <div
              class="ml-3 text-base leading-6 text-gray-300 hover:text-gray-400"
            >
              <a href="mailto:info@roofonecapital.com"
                >info@roofonecapital.com</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
