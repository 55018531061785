<template>
  <main>
    <section>
      <div class="relative overflow-hidden bg-white">
        <Blob2 class="absolute left-0 mt-16 opacity-25 w-72 h-72 top-128" />
        <Blob1
          class="absolute right-0 w-64 h-64 opacity-25 top-24"
          style="z-index: -1;"
        />

        <div class="mt-40 mx-auto max-w-screen-xl px-4 pb-6 sm:px-6 lg:mt-48">
          <div class="">
            <h2 class="text-4xl tracking-tight leading-10 text-center font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
              Own something
              <span class="text-lime-600"> real</span>
            </h2>
            <p class="mt-5 max-w-md mx-auto text-base text-gray-500 sm:text-lg sm:text-center md:text-xl md:max-w-3xl">
              With a secure escrow, flexible payment plans and valuable decision-making data on each property,
              Roofone is the smartest way for you to buy real estate in Nigeria.
            </p>
            <div class="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
              <div class="rounded-md shadow">
                <router-link to="/properties" class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-lime-600 hover:bg-lime-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
                  Get started
                </router-link>
              </div>
              <div class="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
                <a @click="scrollToWhyRoofone" href="#why-roofone" class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-gray-600 bg-white hover:text-gray-500 focus:outline-none focus:border-indigo-300 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
                  Learn more
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="why-roofone" class="mt-32 pb-5 mx-auto max-w-7xl lg:mt-48">
      <div class="px-0 mx-auto md:px-4">
        <div class="flex flex-col items-center">
          <h1
            class="relative w-auto text-3xl font-extrabold text-gray-700 md:text-4xl font-heading-sans"
          >
            Why Roofone?
            <div class="absolute w-32 h-2 top-14 bg-lime-600"></div>
          </h1>
        </div>
        <div class="flex flex-col items-center w-full lg:flex-row">
          <div
            class="w-full px-4 pt-6 text-center md:w-8/12 lg:pt-12 lg:w-4/12"
          >
            <div
              class="relative flex flex-col w-full min-w-0 break-words h-80 lg:h-96"
            >
              <div class="px-4 py-6 lg:px-10">
                <SmartIllustration class="w-8/12 h-40 m-auto" />

                <h6
                  class="mt-5 text-xl font-semibold text-gray-700 font-heading-sans"
                >
                  Buy Smarter
                </h6>
                <p
                  class="mt-8 mb-4 text-base leading-relaxed tracking-normal text-left text-gray-500"
                >
                  Make better-informed buying decisions with in-depth data on
                  every property listed on Roofone.
                </p>
              </div>
            </div>
          </div>
          <div
            class="w-full px-4 pt-24 text-center md:w-8/12 lg:pt-12 lg:w-4/12"
          >
            <div
              class="relative flex flex-col w-full min-w-0 break-words h-80 lg:h-96"
            >
              <div class="flex-auto px-4 py-6 lg:px-10">
                <SecureIllustration class="w-8/12 h-40 m-auto" />

                <h6
                  class="mt-5 text-xl font-semibold text-gray-700 font-heading-sans"
                >
                  Buy Safer
                </h6>
                <p
                  class="mt-8 mb-4 text-base leading-relaxed tracking-normal text-left text-gray-500"
                >
                  You don't pay directly to Roofone, or the seller. Your funds
                  remain with an SEC-regulated trustee until you are happy with
                  your purchase.
                </p>
              </div>
            </div>
          </div>
          <div
            class="w-full px-4 pt-24 text-center md:w-8/12 lg:pt-12 lg:w-4/12"
          >
            <div
              class="relative flex flex-col w-full min-w-0 break-words h-80 lg:h-96"
            >
              <div class="flex-auto px-4 py-6 lg:px-10">
                <AssistanceIllustration class="w-8/12 h-40 m-auto" />

                <h6
                  class="mt-5 text-xl font-semibold text-gray-700 font-heading-sans"
                >
                  Lifetime Support
                </h6>
                <p
                  class="mt-8 mb-4 text-base leading-relaxed tracking-normal text-left text-gray-500"
                >
                  You get continuous updates on how new market trends impact the
                  value of your property.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <HowItWorks />
    </section>

    <section>
      <Testimonials />
    </section>

    <section>
      <Contact />
    </section>

    <section>
      <div
        class="flex flex-col items-center max-w-screen-xl py-12 pt-24 mx-auto lg:pt-32"
      >
        <h2
          class="relative text-3xl font-extrabold leading-9 tracking-normal text-gray-700 md:text-4xl font-heading-sans sm:text-4xl sm:leading-10"
        >
          Ready to get started?
          <div class="absolute w-32 h-2 top-14 bg-lime-600"></div>
        </h2>
        <p
          class="mt-12 font-sans text-lg leading-9 tracking-tight text-gray-500 sm:text-2xl sm:leading-10"
        >
          Browse through our listings
        </p>
        <div class="flex justify-center mt-8">
          <div class="rounded-md shadow">
            <router-link
              to="/listings"
              class="flex items-center justify-center w-full px-8 py-3 text-base font-medium leading-6 text-white transition duration-150 ease-in-out border border-transparent rounded-md bg-lime-600 hover:bg-lime-500 focus:outline-none focus:shadow-outline md:py-4 md:text-lg md:px-10"
            >
              Find Properties
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import SmartIllustration from '@/components/home/SmartIllustration';
import SecureIllustration from '@/components/home/SecureIllustration';
import AssistanceIllustration from '@/components/home/AssistanceIllustration';
import HowItWorks from '@/components/home/HowItWorks';
import Testimonials from '@/components/home/Testimonials';
import Contact from '@/components/common/Contact';
import Blob1 from '@/components/blobs/Blob1';
import Blob2 from '@/components/blobs/Blob2';

export default {
  components: {
    SmartIllustration,
    SecureIllustration,
    AssistanceIllustration,
    HowItWorks,
    Contact,
    Blob1,
    Blob2,
    Testimonials,
  },

  methods: {
    scrollToWhyRoofone(event) {
      event.preventDefault();

      const section = document.getElementById('why-roofone');
      section.scrollIntoView({ behavior: 'smooth' });
    }
  }
};
</script>
