<template>
  <div :class="variant === 'dark' ? ' bg-gray-800' : ' bg-white'">
    <div class="relative pt-6 pb-6">
      <div class="max-w-screen-xl px-4 mx-auto sm:px-6">
        <nav class="relative flex items-center justify-between sm:h-10 md:justify-end">
          <div class="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
            <div class="flex items-center justify-between w-full md:w-auto">
              <router-link to="/">
                <img
                  class="w-auto h-6 sm:h-8"
                  src="@/assets/img/logo.svg"
                  alt
                />
              </router-link>
              <div class="flex items-center -mr-2 md:hidden">
                <button
                  type="button"
                  class="inline-flex items-center justify-center p-2 text-gray-400 transition duration-150 ease-in-out rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500"
                  @click="toggleMenu"
                >
                  <svg
                    class="w-6 h-6"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div class="hidden md:flex md:items-center" :class="{ 'mr-32' : !user }">
            <router-link
              to="/float-mortgage"
              class="ml-10 font-medium transition duration-150 ease-in-out focus:outline-none"
              :class="[
                variant === 'dark'
                  ? 'text-gray-200 hover:text-gray-300 focus:text-gray-300'
                  : 'text-gray-800 hover:text-gray-900 focus:text-gray-900',
              ]"
              >Float
            </router-link>

            <router-link
              to="/listings"
              class="ml-8 font-medium transition duration-150 ease-in-out focus:outline-none"
              :class="[
                variant === 'dark'
                  ? 'text-gray-200 hover:text-gray-300 focus:text-gray-300'
                  : 'text-gray-800 hover:text-gray-900 focus:text-gray-900',
              ]"
              >Browse properties
            </router-link>

            <router-link
              v-show="!user"
              to="/login"
              class="ml-8 font-medium transition duration-150 ease-in-out focus:outline-none"
              :class="[
                variant === 'dark'
                  ? 'text-gray-200 hover:text-gray-300 focus:text-gray-300'
                  : 'text-gray-800 hover:text-gray-900 focus:text-gray-900',
              ]"
              >Log In
            </router-link>

            <div v-show="!user" class="hidden md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0">
              <span class="inline-flex rounded-md shadow">
                <router-link
                  to="/sign-up"
                  class="inline-flex items-center px-4 py-2 text-base font-medium leading-6 text-white transition duration-150 ease-in-out border border-transparent rounded-md cursor-pointer bg-lime-600 hover:bg-lime-500 focus:outline-none focus:shadow-outline active:bg-lime-700"
                  >Sign Up
                </router-link>
              </span>
            </div>

            <div v-if="user" class="lg:ml-4">
              <div class="flex items-center">

                <!-- Profile dropdown -->
                <div class="ml-3 relative">
                  <div>
                    <button @click="toggleProfileMenu" class="max-w-xs flex items-center text-sm rounded-full focus:outline-none focus:bg-cool-gray-100 lg:p-2 lg:rounded-md lg:hover:bg-cool-gray-100" id="user-menu" aria-label="User menu" aria-haspopup="true">
                      <span class="rounded-full p-1 border-2 border-gray-400">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                          <path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd" />
                        </svg>
                      </span>
                      <p class="hidden ml-3 truncate text-cool-gray-700 text-sm leading-5 font-medium lg:block">
                        {{ user.displayName }}
                      </p>
                      <!-- Heroicon name: chevron-down -->
                      <svg class="hidden flex-shrink-0 ml-1 h-5 w-5 text-cool-gray-400 lg:block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                      </svg>
                    </button>
                  </div>
                  <!--
                    Profile dropdown panel, show/hide based on dropdown state.

                    Entering: "transition ease-out duration-100"
                      From: "transform opacity-0 scale-95"
                      To: "transform opacity-100 scale-100"
                    Leaving: "transition ease-in duration-75"
                      From: "transform opacity-100 scale-100"
                      To: "transform opacity-0 scale-95"
                  -->
                  <transition
                    enter-class="transform opacity-0 scale-95"
                    enter-active-class="transition ease-out duration-100"
                    enter-to-class="transform opacity-100 scale-100"
                    leave-class="transform opacity-100 scale-100"
                    leave-active-class="transition ease-in duration-75"
                    leave-to-class="transform opacity-0 scale-95"
                  >
                    <div @click="toggleProfileMenu" v-show="profileMenuOpen" class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg">
                      <div class="py-1 rounded-md bg-white shadow-xs" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
                        <!--
                        <a href="#" class="block px-4 py-2 text-sm text-cool-gray-700 hover:bg-cool-gray-100 transition ease-in-out duration-150" role="menuitem">Your Profile</a>
                        <a href="#" class="block px-4 py-2 text-sm text-cool-gray-700 hover:bg-cool-gray-100 transition ease-in-out duration-150" role="menuitem">Settings</a>
                        -->
                        <router-link to="/dashboard" class="block px-4 py-2 text-sm text-cool-gray-700 hover:bg-cool-gray-100 transition ease-in-out duration-150" role="menuitem">Dashboard</router-link>
                        <a
                          href="#"
                          @click.prevent="logout"
                          class="block py-2 px-4 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                          role="menuitem"
                        >Logout</a>
                      </div>
                    </div>
                  </transition>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>

      <!-- Mobile menu -->
      <div class="absolute inset-x-0 top-0 z-10 p-2 md:hidden">
        <transition
          enter-class="scale-95 opacity-0"
          enter-active-class="duration-150 ease-out"
          enter-to-class="scale-100 opacity-100"
          leave-class="scale-100 opacity-100"
          leave-active-class="duration-100 ease-in"
          leave-to-class="scale-95 opacity-0"
        >
          <div
            v-show="menuOpen"
            class="transition origin-top-right transform rounded-lg shadow-md"
          >
            <div class="overflow-hidden bg-white rounded-lg shadow-xs">
              <div class="flex items-center justify-between px-5 pt-4">
                <div>
                  <img class="w-auto h-6" src="@/assets/img/logo.svg" alt />
                </div>
                <div class="-mr-2">
                  <button
                    type="button"
                    class="inline-flex items-center justify-center p-2 text-gray-400 transition duration-150 ease-in-out rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500"
                    @click="toggleMenu"
                  >
                    <svg
                      class="w-6 h-6"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div class="z-20 px-2 pt-2 pb-3">
                <router-link
                  class="block px-3 py-2 text-base font-medium text-gray-700 transition duration-150 ease-in-out rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50"
                  to="/float-mortgage"
                  @click.native="menuOpen = false"
                  >Float
                </router-link>
                <router-link
                  class="block px-3 py-2 text-base font-medium text-gray-700 transition duration-150 ease-in-out rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50"
                  to="/listings"
                  @click.native="menuOpen = false"
                  >Browse properties
                </router-link>
                <router-link
                  v-show="!user"
                  class="block px-3 py-2 text-base font-medium text-gray-700 transition duration-150 ease-in-out rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50"
                  to="/login"
                  @click.native="menuOpen = false"
                  >Log In
                </router-link>
              </div>
              <div>
                <router-link
                  v-show="!user"
                  to="/sign-up"
                  class="block w-full px-5 py-3 font-medium text-center transition duration-150 ease-in-out cursor-pointer text-lime-600 bg-gray-50 hover:bg-gray-100 hover:text-lime-600 focus:outline-none focus:bg-gray-100 focus:text-lime-700"
                  @click.native="menuOpen = false"
                  >Create an Account
                </router-link>

                <a
                  v-show="user"
                  to="#"
                  class="block w-full px-5 py-3 font-medium text-center transition duration-150 ease-in-out cursor-pointer text-lime-600 bg-gray-50 hover:bg-gray-100 hover:text-lime-600 focus:outline-none focus:bg-gray-100 focus:text-lime-700"
                  @click.prevent="logout"
                  >Logout
                </a>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { auth } from '@/firebase-auth'

export default {
  props: {
    variant: {
      default: 'light',
      type: String,
    },
  },
  data() {
    return {
      menuOpen: false,
      profileMenuOpen: false,
      navItems: ['How it works', 'Testimonials'],

      user: null,
    };
  },

  created() {
    auth.onAuthStateChanged(user => this.user = user)
  },

  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },

    toggleProfileMenu() {
      this.profileMenuOpen = !this.profileMenuOpen;
    },

    logout() {
      auth.signOut()
      this.$router.push('/')
    }
  },
};
</script>

<style></style>
