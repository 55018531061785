import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';
import Home from './pages/Home.vue';
import { getCurrentUser } from './firebase-auth';

Vue.use(VueRouter);
Vue.use(VueMeta, { keyName: 'head' });

export const createRouter = () => {
  const routes = [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/properties',
      name: 'searchResults',
      component: () => import('./pages/SearchResults.vue'),
    },
    {
      path: '/listings',
      redirect: '/properties',
    },
    /**
     * @todo remove the following route definition at some point.
             It's been replaced by the version with slugs in it.
     */
    {
      path: '/listings/:id',
      name: 'listingDetailsOld',
      component: () => import('./pages/ListingDetails/ListingDetails.vue'),
      alias: '/listings/:id/images',
    },
    {
      path: '/properties/for-sale/:id/:slug',
      name: 'listingDetails',
      component: () => import('./pages/ListingDetails/ListingDetails.vue'),
      alias: '/properties/for-sale/:id/:slug/images',
    },
    {
      path: '/sign-up',
      name: 'signUp',
      component: () => import('./pages/SignUp.vue'),
      meta: { guestOnly: true }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('./pages/Login.vue'),
      meta: { guestOnly: true }
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('./pages/Dashboard.vue'),
      meta: { requiresAuth: true },
    },
    {
      path: '/onboarding',
      name: 'onboarding',
      component: () => import('./pages/Onboarding/Onboarding.vue'),
      meta: { requiresAuth: true },
    },
    {
      path: '/terms',
      name: 'terms',
      component: () => import('./pages/TOC.vue'),
    },
    {
      path: '/pricing',
      name: 'pricing',
      meta: { layout: 'dark' },
      component: () => import('./pages/Pricing.vue'),
    },
    {
      path: '/float-mortgage',
      name: 'float',
      component: () => import('./pages/Mortgage.vue'),
    },
    {
      path: '/mortgage-calculator',
      name: 'mortgage-calculator',
      component: () =>
        import('./pages/MortgageCalculator/MortgageCalculator.vue'),
    },
    {
      path: '/404',
      component: () => import('./pages/NotFound.vue'),
    },
    {
      path: '*',
      component: () => import('./pages/NotFound.vue'),
    },

    //  Commented out till we have some functionality for processing contact form submissions
    // {
    //   path: "/contact",
    //   name: "contact",
    //   component: () => import("../pages/Contact.vue"),
    // },
  ];

  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    // Preserves previous scroll position when using back/forward buttons else defaults to the top
    scrollBehavior(to, from, savedPosition) {
      return savedPosition
        ? savedPosition
        : to.hash
        ? { selector: to.hash }
        : { x: 0, y: 0 };
    },
  });

  router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const guestOnly = to.matched.some(record => record.meta.guestOnly);

    // If we don't need to guard this page (i.e. it's ok for every
    // kind of user to see), we can move forward quickly
    if (requiresAuth === undefined || guestOnly === undefined) {
      return next()
    }

    // if it needs guarding tho, we'll let Cloud Identity load up its
    // initial (authentication) state before we make our decision.
    getCurrentUser()
      .then(user => {
        const someonesLoggedIn = user !== null;

        if (guestOnly && someonesLoggedIn) {
          return next('/dashboard')
        }

        if (requiresAuth && !someonesLoggedIn) {
          return next('/login')
        }

        return next();
      })
  })

  return router;
};
