import { initializeApp } from '@firebase/app'
import { getAuth, connectAuthEmulator } from '@firebase/auth';

export const app = initializeApp({
  apiKey: process.env.VUE_APP_CLOUD_IDENTITY_API_KEY || 'abc123',
  authDomain: process.env.VUE_APP_CLOUD_IDENTITY_AUTH_DOMAIN || null,
});

const auth = getAuth(app);

if (process.env.NODE_ENV === 'development') {
  connectAuthEmulator(auth, process.env.VUE_APP_AUTH_EMULATOR_URL);
}

/**
 * Promise-based abstraction for the recommended way to get the
 * currently active Cloud Identity user. If any.
 *
 * @returns {Promise<User|null>}
 *
 * @see https://github.com/firebase/firebase-js-sdk/issues/462#issuecomment-359711740
 */
export function getCurrentUser() {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
}

export { auth };
