<template>
  <div id="testimonials" class="relative">
    <div class="flex flex-col items-center pt-20 mx-auto mb-16 max-w-7xl">
      <Blob2 class="absolute left-0 mt-16 opacity-25 w-72 h-72 top-128" />
      <Blob1 class="absolute right-0 z-10 w-64 h-64 opacity-25 top-24" />
      <h3 class="text-2xl font-extrabold uppercase text-lime-600">
        Testimonials
      </h3>
      <h2
        class="relative text-3xl font-extrabold text-gray-700 md:text-4xl font-heading-sans"
      >
        Our Customers
        <div class="absolute w-32 h-2 top-14 bg-lime-600"></div>
      </h2>
      <div
        class="flex flex-col items-center justify-around w-full px-16 pt-24 lg:items-start lg:flex-row"
      >
        <div class="flex flex-col items-center flex-shrink-0 mb-20 w-80">
          <!-- <img
            src="https://avatars.dicebear.com/api/avataaars/one.svg?b=%23b2b1b3"
            class="w-24 h-24 rounded-full shadow-inner"
          /> -->
          <blockquote
            class="relative pt-5 text-lg italic leading-loose tracking-normal text-center text-gray-600"
          >
            <div
              style="font-size: 5rem;"
              class="absolute top-0 left-0 leading-none text-gray-300"
            >
              &ldquo;
            </div>
            I can see that with RPC, "what you see is what you get". I have no
            regret in starting my real estate journey with RPC. Seamless
            transactions, documents delivered to my doorstep.
          </blockquote>
          <p class="text-xl font-extrabold text-gray-700 mt-7">
            - PAUL SHAIBU
          </p>
        </div>

        <div class="flex flex-col items-center flex-shrink-0 mb-20 w-80">
          <!-- <img
            src="https://avatars.dicebear.com/api/avataaars/two.svg?b=%23b2b1b3"
            class="w-24 h-24 rounded-full shadow-inner"
          /> -->
          <blockquote
            class="relative pt-5 text-lg italic leading-loose tracking-normal text-center text-gray-600"
          >
            <div
              style="font-size: 5rem;"
              class="absolute top-0 left-0 leading-none text-gray-300"
            >
              &ldquo;
            </div>
            Been thinking of investing in real estate for a long time then I
            spoke to a member of the team and booked an inspection for my next
            visit to Nigeria. On getting there I purchased 3 plots instead of
            one!
          </blockquote>
          <p class="text-xl font-extrabold text-gray-700 mt-7">
            - MRS FATOKI
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Blob1 from "@/components/blobs/Blob1";
import Blob2 from "@/components/blobs/Blob2";
export default {
  components: {
    Blob1,
    Blob2,
  },
};
</script>

<style></style>
