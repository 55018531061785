<template>
  <div id="howItWorks" class="relative">
    <Blob2 class="absolute left-0 mt-16 opacity-25 w-72 h-72 top-128" />
    <Blob1 class="absolute right-0 w-64 h-64 opacity-25 top-24" />
    <div class="px-5 mx-auto my-20 lg:px-16 max-w-7xl">
      <div class="flex flex-col items-center">
        <h2
          class="relative w-auto text-3xl font-extrabold text-gray-700 md:text-4xl font-heading-sans"
        >
          How Roofone works
          <div class="absolute w-32 h-2 top-14 bg-lime-600"></div>
        </h2>
        <div
          class="flex flex-col-reverse items-center justify-between w-full mt-24 md:flex-row md:h-80"
          data-aos="fade-up"
          data-aos-delay="50"
          data-aos-duration="700"
          data-aos-easing="ease-in-out"
          data-aos-once="true"
        >
          <div class="w-full h-full md:w-4/12 lg:w-5/12">
            <div
              class="mt-5 text-4xl font-extrabold md:mt-0 text-lime-800"
            >
              Search
            </div>
            <p class="mt-5 text-lg text-gray-700">
              Browse properties and use custom filters to tailor your search by
              property price, location, desired return, vacancy rate and many
              more.
            </p>
          </div>
          <img
            src="@/assets/img/searchIllustration.svg"
            class="w-full rounded-lg h-72 md:mb-0 md:w-6/12"
          />
        </div>

        <div
          class="flex flex-col items-center justify-between w-full mt-32 md:flex-row md:h-80"
          data-aos="fade-up"
          data-aos-delay="50"
          data-aos-duration="700"
          data-aos-easing="ease-in-out"
          data-aos-once="true"
        >
          <img
            src="@/assets/img/analyzeIllustration.svg"
            class="w-full rounded-lg h-72 md:mb-0 md:w-6/12"
          />
          <div class="w-full md:w-4/12">
            <div
              class="mt-5 text-4xl font-extrabold md:mt-0 text-lime-800"
            >
              Analyze
            </div>
            <p class="mt-5 text-lg text-gray-700">
              View extensive details on each property.
            </p>
            <div class="flex mt-3 text-gray-600">
              <CheckIcon
                class="flex-shrink-0 w-6 h-6 mr-2 text-lime-600"
              />
              <div class="text-base">
                Property features, pictures, floor plan.
              </div>
            </div>
            <div class="flex mt-3 text-gray-600">
              <CheckIcon
                class="flex-shrink-0 w-6 h-6 mr-2 text-lime-600"
              />
              <div class="text-base">See estimated returns on investment.</div>
            </div>
            <div class="flex mt-3 text-gray-600">
              <CheckIcon
                class="flex-shrink-0 w-6 h-6 mr-2 text-lime-600"
              />
              <div class="text-base">
                Check out property financials e.g. estimated rent and Land Use
                Charge
              </div>
            </div>
          </div>
        </div>

        <div
          data-aos="fade-up"
          data-aos-delay="50"
          data-aos-duration="700"
          data-aos-easing="ease-in-out"
          data-aos-once="true"
          class="flex flex-col-reverse items-center justify-between w-full mt-32 md:flex-row md:h-80"
        >
          <div class="w-full md:w-4/12">
            <div
              class="mt-5 text-4xl font-extrabold md:mt-0 text-lime-800"
            >
              Escrow
            </div>
            <p class="mt-5 text-lg text-gray-700">
              Make a bid with a click of a button.
            </p>
            <div class="flex mt-3 text-gray-600">
              <CheckIcon
                class="flex-shrink-0 w-6 h-6 mr-2 text-lime-600"
              />
              <div class="text-base">Placing a bid is completely free</div>
            </div>
            <div class="flex mt-3 text-gray-600">
              <CheckIcon
                class="flex-shrink-0 w-6 h-6 mr-2 text-lime-600"
              />
              <div class="text-base">
                If your offer is accepted, Roofone's escrow opens, and you can make a payment.
              </div>
            </div>
            <div class="flex mt-3 text-gray-600">
              <CheckIcon
                class="flex-shrink-0 w-6 h-6 mr-2 text-lime-600"
              />
              <div class="text-base">
                During escrow, Roofone's legal team provides a property search report and
                all title documents for your perusal.
              </div>
            </div>
            <div class="flex mt-3 text-gray-600">
              <CheckIcon
                class="flex-shrink-0 w-6 h-6 mr-2 text-lime-600"
              />
              <div class="text-base">
                Roofone's escrow is operated by an SEC Regulated Body.
              </div>
            </div>
          </div>
          <img
            src="@/assets/img/escrowIllustration.svg"
            class="w-full rounded-lg h-72 md:mb-0 md:w-6/12"
          />
        </div>

        <div
          class="flex flex-col items-center justify-between w-full mt-32 md:flex-row md:h-80"
          data-aos="fade-up"
          data-aos-delay="50"
          data-aos-duration="700"
          data-aos-easing="ease-in-out"
          data-aos-once="true"
        >
          <img
            src="@/assets/img/closeIllustration.svg"
            class="w-full rounded-lg h-72 md:mb-0 md:w-6/12"
          />
          <div class="w-full md:w-4/12">
            <div
              class="mt-5 text-4xl font-extrabold md:mt-0 text-lime-800"
            >
              Close
            </div>
            <p class="mt-5 text-lg text-gray-700">
              Get your documents delivered to your home.
            </p>
            <div class="flex mt-3 text-gray-600">
              <CheckIcon
                class="flex-shrink-0 w-6 h-6 mr-2 text-lime-600"
              />
              <div class="text-base">
                Once both parties are happy with the exchange, escrow closes.
              </div>
            </div>
            <div class="flex mt-3 text-gray-600">
              <CheckIcon
                class="flex-shrink-0 w-6 h-6 mr-2 text-lime-600"
              />
              <div class="text-base">
                We deliver the keys and all documents to you.
              </div>
            </div>
            <div class="flex mt-3 text-gray-600">
              <CheckIcon
                class="flex-shrink-0 w-6 h-6 mr-2 text-lime-600"
              />
              <div class="text-base">
                We connect you with property managers and service
                providers.
              </div>
            </div>
            <div class="flex mt-3 text-gray-600">
              <CheckIcon
                class="flex-shrink-0 w-6 h-6 mr-2 text-lime-600"
              />
              <div class="text-base">Get lifetime support.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CheckIcon from "@/components/icons/CheckIcon";
import Blob1 from "@/components/blobs/Blob1";
import Blob2 from "@/components/blobs/Blob2";

export default {
  components: {
    CheckIcon,
    Blob1,
    Blob2,
  },
};
</script>

<style></style>
