<template>
  <div class="flex flex-col">
    <PageHeader variant="dark" />
    <slot />
    <PageFooter />
  </div>
</template>

<script>
import PageHeader from "@/components/common/Navbar";
import PageFooter from "@/components/common/Footer";
export default {
  components: { PageHeader, PageFooter },
};
</script>

<style></style>
